import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import NewsPageItem from "../components/news/newsPageItem";
import LatestNews from "../components/news/newsLatest";

import {
  newsItemsWrapper,
  newsItemsRest,
  newsHeader,
} from "../css/news.module.css";

const NewsPage = ({ data }) => {
  const {
    allStrapiBlog: { edges },
  } = data;

  return (
    <>
      <Helmet>
        <title>News | Pannal Village Hall</title>
      </Helmet>
      <div className={newsHeader}>
        <h1>News</h1>
        <p>Latest from Pannal Village Hall</p>
      </div>
      <LatestNews news={edges[0].node} />
      <div className={newsItemsWrapper}>
        <div className={newsItemsRest}>
          {edges.slice(1).map((item) => (
            <NewsPageItem news={item.node} key={item.node.id} />
          ))}
        </div>
      </div>
    </>
  );
};

export default NewsPage;

export const query = graphql`
  {
    allStrapiBlog(sort: { fields: created_at, order: DESC }) {
      edges {
        node {
          id
          slug
          blogTitle
          created_at
          blogText
          mainImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
