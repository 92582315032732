import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import TextTruncate from "react-text-truncate";

import {
  newsLink,
  newsPageText,
  imageBox,
  newsCard,
} from "../../css/newspageitem.module.css";

const NewsPageItem = ({ news }) => {
  return (
    <div className={newsCard}>
      <div className={imageBox}>
        <GatsbyImage
          image={news.mainImage.childImageSharp.gatsbyImageData}
          alt={news.blogTitle}
          imgStyle={{ objectFit: "cover" }}
          style={{ height: "100%" }}
        />
      </div>
      <div className={newsPageText}>
        <p>{news.blogTitle}</p>
        <p>
          <TextTruncate
            line={3}
            element="span"
            truncateText="..."
            text={news.blogText}
          />
        </p>
        <Link to={news.slug} className={newsLink}>
          Read more
        </Link>
      </div>
    </div>
  );
};

export default NewsPageItem;
