import React from "react";
import { Link } from "gatsby";
import Moment from "react-moment";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  newsLatestTitle,
  newsLatestImage,
  newsLatest,
  newsLatestWrapper,
} from "../../css/news.module.css";

const LatestNews = ({ news }) => {
  return (
    <div className={newsLatestWrapper}>
      <div className={newsLatest}>
        <div className={newsLatestImage}>
          <GatsbyImage
            image={news.mainImage.childImageSharp.gatsbyImageData}
            alt={news.blogTitle}
            imgStyle={{ objectFit: "cover" }}
            style={{ height: "100%" }}
          />
        </div>
        <div className={newsLatestTitle}>
          <span>
            <Moment format="MMMM YYYY">{news.created_at}</Moment>
          </span>
          <p>{news.blogTitle}</p>
          <Link to={news.slug}>Read more</Link>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
